
import { mapActions, mapGetters, mapMutations } from "vuex"
import { MAX_CHARACTER_LIMIT } from "@/constants"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { REPLY_TEMPLATE } from "@/constants/bread-crumbs/reply-template"
import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
export default {
  name      : "ReplyTemplate",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data: () => ({
    localReplyTemplate                : undefined,
    replyTemplateNameCharacterLimit   : MAX_CHARACTER_LIMIT.REPLY_TEMPLATE_NAME,
    replyTemplateContentCharacterLimit: MAX_CHARACTER_LIMIT.REPLY_TEMPLATE_CONTENT,
    displayContentSaveAndCancelButton : false,
    isReplyTemplateFocused            : false,
    isReplyTemplateNameDuplicate      : false,
    showRemoveReplyTemplateDialog     : false,
    replyTemplateToBeRemoved          : undefined
  }),
  computed: {
    ...mapGetters({
      replyTemplates                : "replyTemplates/replyTemplates",
      isLoadingReplyTemplates       : "replyTemplates/isLoadingReplyTemplates",
      isUpdatingReplyTemplateName   : "replyTemplates/isUpdatingReplyTemplateName",
      isUpdatingReplyTemplateContent: "replyTemplates/isUpdatingReplyTemplateContent",
      isReplyTemplateNameUpdated    : "replyTemplates/isReplyTemplateNameUpdated",
      isReplyTemplateContentUpdated : "replyTemplates/isReplyTemplateContentUpdated",
      replyTemplateNameUpdateError  : "replyTemplates/replyTemplateNameUpdateError",
      isRemovingReplyTemplate       : "replyTemplates/isRemovingReplyTemplate",
      replyTemplateRemoved          : "replyTemplates/replyTemplateRemoved"
    }),
    replyTemplateId() {
      return +this.$route.params.id
    },
    replyTemplate() {
      return this.replyTemplates.find(replyTemplate => replyTemplate.id === this.replyTemplateId)
    },
    isReplyTemplateNameChanged() {
      return this.replyTemplate?.name !== this.localReplyTemplate?.name
    }
  },
  methods: {
    ...mapActions({
      updateReplyTemplate: "replyTemplates/updateReplyTemplate",
      removeReplyTemplate: "replyTemplates/removeReplyTemplate",
      notify             : "shared/notify"
    }),
    ...mapMutations({
      setBreadcrumbs             : "shared/setBreadcrumbs",
      setPageTitle               : "shared/setPageTitle",
      setReplyTemplateUpdateError: "replyTemplates/setReplyTemplateUpdateError"
    }),
    handleUpdateContent() {
      this.updateReplyTemplate({
        id     : this.localReplyTemplate.id,
        content: this.localReplyTemplate.content
      })
    },
    handleNameInputOnBlurEvent(onBlur){
      onBlur()
      this.verifyAndUpdateName()
    },
    handleNameInputOnEnter() {
      this.$refs.input_reply_template_name.blur()
    },
    verifyAndUpdateName(){
      if (this.isReplyTemplateNameChanged) {
        if (this.localReplyTemplate.name &&
          this.localReplyTemplate.name.length <= this.replyTemplateNameCharacterLimit) {
          this.updateReplyTemplate({
            id  : this.localReplyTemplate.id,
            name: this.localReplyTemplate.name
          })
        }
      }
    },
    handleContentOnFocusEvent(onFocus) {
      onFocus()
      this.displayContentSaveAndCancelButton = true
      this.isReplyTemplateFocused            = true
    },
    handleContentOnBlurEvent(onBlur) {
      onBlur()
      this.isReplyTemplateFocused = false
    },
    handleCancelUpdateContent() {
      this.localReplyTemplate.content        = this.replyTemplate.content
      this.displayContentSaveAndCancelButton = false
      this.isReplyTemplateFocused            = false
    },
    handleRemoveReplyTemplate() {
      this.replyTemplateToBeRemoved = this.replyTemplate
      this.removeReplyTemplate(this.replyTemplate.id)
    }
  },
  watch: {
    replyTemplate: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localReplyTemplate) {
          this.localReplyTemplate = { ...newValue }
        }
      }
    },
    isReplyTemplateNameUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.setBreadcrumbs(
            REPLY_TEMPLATE({ params: { id: this.localReplyTemplate.id } }, this.localReplyTemplate.name
            )
          )
          this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(this.localReplyTemplate.name))
        }
      }
    },
    isReplyTemplateContentUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.displayContentSaveAndCancelButton = false
        }
      }
    },
    replyTemplateNameUpdateError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.field === "name" && newValue.type === "duplicate") {
          this.isReplyTemplateNameDuplicate = true
        } else {
          this.isReplyTemplateNameDuplicate = false
        }
      }
    },
    "localReplyTemplate.name": {
      immediate: true,
      handler  : function() {
        if (this.replyTemplateNameUpdateError) {
          this.setReplyTemplateUpdateError(["name"])
        }
      }
    },
    replyTemplateRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "1746",
            parameters: {
              replyTemplateName: this.replyTemplateToBeRemoved.name
            }
          })
          this.showRemoveReplyTemplateDialog = false
          this.replyTemplateToBeRemoved      = undefined
          this.$router.push({
            name: "reply-templates"
          })
        }
      }
    },
    isRemovingReplyTemplate: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_REPLY_TEMPLATE.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_REPLY_TEMPLATE.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}